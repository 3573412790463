<template>
  <div class="merchprdconfigbulk">
    	<div class="row">
			<div id="content" class="col-sm-12">
				<div class="page-login">
					<div class="account-border">
						<div class="row">
              <div class="col-md-12" id="preview-area" v-if="blockLoading">
                <div class="row text-center">
                  <div class="sk-chase">
                    <div class="sk-chase-dot"></div>
                    <div class="sk-chase-dot"></div>
                    <div class="sk-chase-dot"></div>
                    <div class="sk-chase-dot"></div>
                    <div class="sk-chase-dot"></div>
                    <div class="sk-chase-dot"></div>
                  </div>
                </div>
              </div>
								<div v-if="!blockLoading" class="col-lg-12 col-md-12 col-sm-12">
									<h2>Product Configure Bulk Upload</h2>
                        <p style="margin-bottom:0px; margin-top:5px; color:#0c8bcc; font-size:14px;">Format .csv .xlsx .xls</p>
                        <br/>
                        <input type="file" ref="prdbulkfile" accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel" value="">
                        <a style="margin-bottom:0px; margin-top:5px; color:#0c8bcc; font-size:14px;" href="xlSheets/cochchibulkupload.xlsx" >Download Sample File</a>
                        <a style="display: block; margin-bottom:0px; margin-top:5px; color:#0c8bcc; font-size:14px;" :href="categoryExcel" >Download Category,Attribute and Brand list</a>
                    </div>
                    <div class="col-lg-12 col-md-12 col-sm-12 bulk-upload-btn mb-5" style="margin-botom: 20px;">
                        <button v-if="!blockLoading" :disabled="isSubmited" class="btn btn-sm merchprof-sbtn" @click="uploadProductBulkFile()" style="margin-bottom: 15px;">Upload</button>
                    </div>     
						</div>
					</div>					
				</div>
			</div>
		</div>
  </div>
</template>
<script>
import mixinProduct from "@/mixins/merchant/APIProduct";
import Domain from "@/mixins/domain";
export default {
  mixins: [Domain,mixinProduct],
  data: function () {
    return {
        selectedFile: null,
        blockLoading: true,
        categoryExcel: "",
        isSubmited: false,
    }
  },
  created() {
    this.haddleDownlord()
  },
  methods: {
    uploadProductBulkFile: async function() {
        this.selectedFile = this.$refs.prdbulkfile.files[0];
        const fd = new FormData();  
        fd.append('xl_file',this.selectedFile,this.selectedFile.name); 
        try {
            this.isSubmited = true;
            let response = await this.postUploadProductBulk(fd);
            this.$swal.fire({
                position: 'center',
                type: 'success',
                title: 'Product Bulk Uploaded!',
                showConfirmButton: false,
                timer: 1500
            }); 
            this.$refs.prdbulkfile.value = "";
            this.isSubmited = false;
        } catch (error) {
            this.$swal.fire({
                position: 'center',
                type: 'error',
                title: error.message,
                showConfirmButton: false,
                timer: 1500
            });
          this.$refs.prdbulkfile.value = "";
          this.isSubmited = false; 
        }
    },
    haddleDownlord: async function() {
      try {
          this.blockLoading = true;
          let res = await this.getDownlordSheet();
          let domainUrl = Domain.APP_DOMAIN.split("api/v1/")[0];
          let newUrl = domainUrl + res.url;
          this.categoryExcel = newUrl;
          this.blockLoading = false;
          // console.log(newUrl);
          // window.open(newUrl);
      } catch (error) {
          this.blockLoading = false;
          this.$swal.fire({
              position: 'center',
              type: 'error',
              title: error.message,
              showConfirmButton: false,
              timer: 1500
          }); 
      }
    }
  }
}
</script>
<style>
.merchprdconfigbulk .merchprof-sbtn{
  background: #5779ae !important;
  color: #ffffff;
  border: none;
}
.merchprdconfigbulk .merchprof-sbtn:hover{
  color: #000000;
}
.bulk-upload-btn{
    padding-top: 15px;
    text-align: right;
}
.bulk-instruction{
    margin-bottom: 25px;
    font-weight: 600;
}

#preview-area {
  width: 60px;
  height: 60px;
  position: absolute;
  left: 0;
  right: 0;
  margin: auto;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.sk-chase {
  width: 40px;
  height: 40px;
  position: relative;
  animation: sk-chase 2.5s infinite linear both;
  z-index: 5;
}
.sk-chase-dot {
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  animation: sk-chase-dot 2s infinite ease-in-out both;
}
.sk-chase-dot:before {
  content: "";
  display: block;
  width: 25%;
  height: 25%;
  background-color: #5779ae;
  border-radius: 100%;
  animation: sk-chase-dot-before 2s infinite ease-in-out both;
}
.sk-chase-dot:nth-child(1) {
  animation-delay: -1.1s;
}
.sk-chase-dot:nth-child(2) {
  animation-delay: -1s;
}
.sk-chase-dot:nth-child(3) {
  animation-delay: -0.9s;
}
.sk-chase-dot:nth-child(4) {
  animation-delay: -0.8s;
}
.sk-chase-dot:nth-child(5) {
  animation-delay: -0.7s;
}
.sk-chase-dot:nth-child(6) {
  animation-delay: -0.6s;
}
.sk-chase-dot:nth-child(1):before {
  animation-delay: -1.1s;
}
.sk-chase-dot:nth-child(2):before {
  animation-delay: -1s;
}
.sk-chase-dot:nth-child(3):before {
  animation-delay: -0.9s;
}
.sk-chase-dot:nth-child(4):before {
  animation-delay: -0.8s;
}
.sk-chase-dot:nth-child(5):before {
  animation-delay: -0.7s;
}
.sk-chase-dot:nth-child(6):before {
  animation-delay: -0.6s;
}
@keyframes sk-chase {
  100% {
    transform: rotate(360deg);
  }
}
@keyframes sk-chase-dot {
  80%,
  100% {
    transform: rotate(360deg);
  }
}
@keyframes sk-chase-dot-before {
  50% {
    transform: scale(0.4);
  }
  100%,
  0% {
    transform: scale(1);
  }
}
</style>